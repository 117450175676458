<template>
    <div class="add_update_account">
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_table" :width="global.dialogTable">
            <div>
                <el-form ref="dialogForm" :label-width="formLabelWidth">
                    <el-row v-if="dialogVisible">
                        <el-col :span="12">
                            <el-form-item label="菜单权限:" required>
                                <el-tree :props="props" show-checkbox :data="statusList" node-key="menuId" ref="tree" :default-checked-keys="musdefaultChecked"></el-tree>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="数据权限:">
                                <el-tree
                                    :props="jsprops"
                                    show-checkbox
                                    node-key="num"
                                    ref="orgTree"
                                    :default-checked-keys="orgdefaultChecked"
                                    :default-expanded-keys="orgdefaultOpen"
                                    lazy
                                    :check-strictly="true"
                                    render-after-expand
                                    :load="loadNode"
                                    :render-content="renderTreeNode"
                                ></el-tree>
                            </el-form-item>

                            <div class="tip_warning">
                                数据权限说明：
                                <br />
                                1.用户所属机构数据权限。 用户创建后，自动获取用户所属机构本身以及所有子机构，子子机构数据权限。
                                <br />
                                2.用户角色配置的数据权限。 通过角色配置数据权限，只包含选中机构本身的数据权限，不包含配置机构除本身之外的子机构，子子机构数据权限。
                                用户在配置机构上创建的子机构，对于用户也是不可见。
                            </div>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                // 菜单默认选中
                musdefaultChecked: [],
                // 组织机构默认选中
                orgdefaultChecked: [],
                orgdefaultOpen: [],
                // 菜单权限
                mus: false,
                // 数据权限
                orgs: false,

                statusList: [],
                //返显过滤父级菜单
                pmid:new Set(),
                props: {
                    children: "children",
                    label: "name",
                    value: "menuId"
                },
                jsprops: {
                    children: "children"
                },
                title: "权限设置",
                roleId: "",
                dialogVisible: false,
                formLabelWidth: "100px"
            };
        },
        watch: {
            dialogVisible(newview) {
                if (!newview) {
                    this.roleId = "";
                    this.musdefaultChecked = [];
                    this.orgdefaultChecked = [];
                    this.mus = false;
                    this.orgs = false;
                }
            }
        },
        methods: {
            // 数据权限
            loadNode(node, resolve) {
                let id = "";
                if (node.data && node.data.type === 0) {
                    id = node.data.data.orgId;
                }
                this.$http
                    .post(
                        "/sys/org/queryOrgTree",
                        {
                            orgId: id,
                            entryP: 0
                        },
                        { isRequestParam: false }
                    )
                    .then(res => {
                        const childNodes = res.detail[0].children;
                        childNodes.forEach(v => {
                            v.num = v.data.num;
                        });
                        resolve(childNodes);
                    });
            },
            renderTreeNode(h, { data }) {
                const { data: val } = data;
                return <span>{val.orgNa}</span>;
            },
            // 菜单权限
            async getAllList() {
                let url = "/sys/role/pageQueryMenu";
                await this.$http
                    .post(url, {
                        sysMenu: {}
                    })
                    .then(({ detail }) => {
                        this.statusList = detail;
                        this.statusList.forEach(v => {
                            this.handleMenuList(v);
                        });
                    });
            },
            handleMenuList(menu) {
                if (menu.children) {
                    menu.children.forEach(m => {
                        this.handleMenuList(m);
                    });
                }

                menu.name = menu.data.name;
                menu.menuId = menu.data.menuId;
                menu.pMenuId = menu.data.pMenuId;
            },
            //获取角色权限
            getList() {
                let url = "/sys/role/queryRoleMenu";
                this.$http.post(url, this.roleId, { isRequestParam: false }).then(({ detail }) => {
                    let list = []
                    if (detail.list && detail.list.length) {
                        list = detail.list.map(v => v.menuId);
                    }
                    list.forEach(menuId=>{
                        this.findPmenuId(this.statusList,menuId)
                    })
                    this.musdefaultChecked = list.filter(one=>!this.pmid.has(one))
                });
            },
            findPmenuId(list,menuId){
                for (var i=0;i<list.length;i++){
                    if(list[i].menuId == menuId){
                        this.pmid.add(list[i].pMenuId)
                        return false
                    }
                    if(list[i].children&&list[i].children.length){
                        this.findPmenuId(list[i].children,menuId)
                    }
                }
            },
            // 获取数据权限
            getzzList() {
                let url = "/sys/role/queryRoleOrg";
                this.$http.post(url, this.roleId, { isRequestParam: false }).then(({ detail }) => {
                    if (detail && detail.length) {
                        const checkedKeys = detail.map(v => v.num);
                        let openKeys = new Set();
                        detail.forEach(v => {
                            const list = v.path.split("/");
                            list.forEach(v => {
                                if (v && !isNaN(v) && !checkedKeys.includes(Number(v))) {
                                    openKeys.add(Number(v));
                                }
                            });
                        });
                        this.orgdefaultOpen = [...openKeys];
                        this.orgdefaultChecked = checkedKeys;
                    } else {
                        this.orgdefaultOpen = [];
                        this.orgdefaultChecked = [];
                    }
                });
            },
            async init(option) {
                this.pmid.clear()
                this.orgdefaultOpen = [];
                this.orgdefaultChecked = [];
                this.dialogVisible = true;
                await this.getAllList();
                if (option) {
                    this.roleId = option.roleId;
                    this.getList();
                    this.getzzList();
                }
            },
            cancel() {
                this.dialogVisible = false;
                console.log("cancel");
            },
            save() {
                // let url = "/sys/role/roleJurisdictionSetting";
                let url = "/sys/role/adjustRoleOrgAndMenu";
                const sysRoleMenuList = this.$refs.tree.getCheckedNodes(false,true);
                const sysRoleOrgList = this.$refs.orgTree.getCheckedNodes();
                if (!sysRoleMenuList.length) {
                    this.$message({
                        type: "warning",
                        message: "请选择菜单权限"
                    });
                    return false;
                }
                this.$http
                    .post(url, {
                        sysRoleMenuList: sysRoleMenuList.map(v => {
                            return {
                                roleId: this.roleId,
                                menuId: v.menuId,
                                appId: "schedule"
                            };
                        }),
                        sysRoleOrgList: sysRoleOrgList.map(v => {
                            return {
                                roleId: this.roleId,
                                orgId: v.data.orgId,
                                appId: "schedule"
                            };
                        })
                    })
                    .then(res => {
                        this.$message({
                            type: "success",
                            message: res.msg
                        });
                        this.dialogVisible = false;
                        this.$emit("updateList");
                    });
            }
        }
    };
</script>
<style scoped>
    .tip_warning {
        color: red;
        font-size: 13px;
    }
</style>
