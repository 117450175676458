<template>
    <div id="role_manage">
        <jy-query ref="form" :model="form">
            <jy-query-item prop="roleNa" label="角色名称:">
                <el-input v-model="form.roleNa" placeholder="请输入"></el-input>
            </jy-query-item>
            <!-- <jy-query-item prop="createUserName" label="创建者:">
                <el-input v-model="form.createUserName" placeholder="请输入"></el-input>
            </jy-query-item>
            <jy-query-item prop="createTime" label="添加时间:" label-width="90px" :span="1.5">
                <el-date-picker v-model="form.createTime" value-format="yyyy-MM-dd" type="date" placeholder="请选择"></el-date-picker>
            </jy-query-item> -->
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('scheduleSystemSettingsRoleManagementList')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('scheduleSystemSettingsRoleManagementReset')">重置</el-button>
                <el-row>
                    <el-button type="primary" v-on:click="addUdate()" v-if="btnexist('scheduleSystemSettingsroleManagementInsert')">新增</el-button>
                    <!-- <el-button type="primary">批量删除</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="jyTable" :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <!-- <jy-table-column type="selection" width="40"></jy-table-column> -->
            <jy-table-column prop="roleNa" label="角色名称" min-width="120"></jy-table-column>
            <jy-table-column prop="orgName" label="所属机构" min-width="120"></jy-table-column>
            <jy-table-column prop="descp" label="角色说明" min-width="200" class-name="align_left"></jy-table-column>
            <!-- <jy-table-column prop="createName" label="创建者" min-width="120"></jy-table-column> -->
            <jy-table-column prop="createT" label="创建日期" min-width="160"></jy-table-column>
            <!-- <jy-table-column prop="remark" label="备注" min-width="160" class-name="align_left"></jy-table-column> -->
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <add-update-role ref="addUpdateRole" @updateList="getList"></add-update-role>
        <permissions ref="permissions" @updateList="getList"></permissions>
    </div>
</template>
<script>
import addUpdateRole from "@/components/pages/admin/systemSettings/roleManagement/addUpdateRole";
import permissions from "@/components/pages/admin/systemSettings/roleManagement/permissions";
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            form: {
                // createUserName: "",
                roleNa: "",
                // createTime: "",
            },
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: [],
            operateList: [],

            btnMenuId:'scheduleSystemSettingsRoleManagement',
        };
    },
    mixins:[btnMixins],
    components: {
        addUpdateRole,
        permissions,
    },
    created() {
        this.getList();
        this.setOperateList();
    },
    methods: {
        setOperateList() {
            let l = [
                {
                    name: "修改",
                    icon: "el-icon-edit-outline",
                    fun: this.addUdate,
                    isShow:()=>{
                        return this.btnexist('scheduleSystemSettingsRoleManagementUpdate')
                    },
                },
                {
                    name: "删除",
                    icon: "el-icon-delete",
                    fun: this.handleDelete,
                    isShow:()=>{
                        return this.btnexist('scheduleSystemSettingsRoleManagementDelete')
                    },
                },
                {
                    name: "权限设置",
                    icon: "el-icon-view",
                    fun: this.permissionsFun,
                    isShow:()=>{
                        return this.btnexist('scheduleSystemSettingsRoleManagementPermissionSet')
                    },
                },
            ];
            this.operateList = l;
        },
        getList() {
            let option = {
                sysRole:{
                    ...this.form,
                },
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            };
            let url = "/sys/role/pageQueryRole";
            // console.log(option)
            this.$http.post(url, option,{isRequestParam:false}).then(({ detail }) => {
                console.log(detail);
                if (detail) {
                    detail.list.forEach(item=>{
                        item.createT = this.$util.formatTime(item.sysRole.createT)
                        item.roleNa = item.sysRole.roleNa
                        item.roleId = item.sysRole.roleId
                        item.descp = item.sysRole.descp
                        item.orgName = item.sysOrg&&item.sysOrg.orgNa
                        item.orgId = item.sysOrg&&item.sysOrg.orgId
                    })
                    this.dataList = detail.list;
                    this.total = detail.total;
                } else {
                    this.dataList = [];
                    this.total = 0;
                }
            });
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1;
            this.getList();
            console.log("更新");
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.oncheck();
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.getList();
        },

        addUdate(option) {
            if (option) {
                this.$refs.addUpdateRole.init(option, "update");
            } else {
                this.$refs.addUpdateRole.init();
            }
        },
        permissionsFun(option) {
            this.$refs.permissions.init(option);
        },
        // 删除
        handleDelete(row) {
            this.$confirm("是否删除该角色?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let option = row.roleId
                    let url = "/sys/role/deleteRole";
                    this.$http.post(url, option,{isRequestParam:false}).then((res) => {
                        // console.log(res)
                        this.$message({
                            type: "success",
                            message: res.msg,
                        });
                        this.getList();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
    },
};
</script>
